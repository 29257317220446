import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { Common, translate } from '../../../functions';

import Block from '../../common/Block';
import Interest, { Interest as InterestType } from '../../common/Interest';
import HexaSVG from '../../common/HexaSvg';
import HexaTooltip from '../../common/HexaTooltip';

import hexaIcons, { FEATURE } from '../../../assets/hexaIcons';
import config from '../../../assets/config';

import './style.css';
import DataVisualization from '@common/Animations/DataVisualization';

const Home = () => {
  const [tooltip, setTooltip] = useState<number | null>(null);

  const history = useHistory();

  return <div className="home-wrap"></div>;
};

export default Home;
